import React, { useState, useEffect, useRef } from 'react';

const AnimateText: React.FC = () => {
    const name = "Sanishka"; // Replace with your name
    const [namePosition, setNamePosition] = useState({ x: 0, y: 0 });
    const targetPositionRef = useRef({ x: 0, y: 0 });

    useEffect(() => {
        // Set initial position to the center of the screen
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;
        setNamePosition({ x: centerX, y: centerY });
        targetPositionRef.current = { x: centerX, y: centerY };

        const handleMouseMove = (e: MouseEvent) => {
            targetPositionRef.current = { x: e.clientX, y: e.clientY };
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const animatePosition = () => {
            setNamePosition(prev => {
                const dx = targetPositionRef.current.x - prev.x;
                const dy = targetPositionRef.current.y - prev.y;
                return {
                    x: prev.x + dx * 0.1,
                    y: prev.y + dy * 0.1
                };
            });
            requestAnimationFrame(animatePosition);
        };

        const animationFrame = requestAnimationFrame(animatePosition);
        return () => cancelAnimationFrame(animationFrame);
    }, []);

    const containerStyle: React.CSSProperties = {
        height: '100vh',
        backgroundColor: '#f3f4f6',
        overflow: 'hidden',
        position: 'relative',
    };

    const nameStyle: React.CSSProperties = {
        fontSize: '2.25rem',
        fontWeight: 'bold',
        color: '#2563eb',
        position: 'fixed',
        left: `${namePosition.x}px`,
        top: `${namePosition.y}px`,
        transform: 'translate(-50%, -50%)', // Center the text on its position
        pointerEvents: 'none', // Prevent the text from interfering with mouse events
        textShadow: '2px 2px 4px rgba(0,0,0,0.1)', // Add a subtle shadow for better visibility
    };

    return (
        <div style={containerStyle}>
            <span style={nameStyle}>{name}</span>
        </div>
    );
};

export default AnimateText;