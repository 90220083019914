import React from 'react';
import './App.css';
import AnimatedText from "./components/AnimatedText";

function App() {
  return (
      <div><AnimatedText/></div>
  );
}

export default App;
